import React from 'react'
import styles from "./KosovoMap.module.scss"
import kosovo from "../../assets/kosovo.svg";
import Entries from '../entries/Entries';
import { useLanguage } from "../../context/Language/LanguageContext";


const KosovoMap = () => {
  const {currentLanguage} = useLanguage()
  return (
    <div className={styles.contactUs}>
    <div className={styles.textBoxes}>
      <div className={styles.text}>
        <p>{currentLanguage==='en'?'Contact Us':currentLanguage==='sq'?'Na kontaktoni':currentLanguage==='sr'?<>Kontaktiraj nas</>:<>Kontaktiere uns</>}</p>
        <h3>{currentLanguage==='en'?'Get in Touch with Us!':currentLanguage==='sq'?'Kontaktoni me Ne!':currentLanguage==='sr'?<>Javite nam se!</>:<>Nehmen Sie Kontakt mit uns auf!</>}</h3>
      </div>
      <Entries/>
    </div>
    <div className={styles.kosovoMap}>
      <img src={kosovo} alt=""/>
      <div className={styles.dot}/>
      <svg className={styles.mapText} viewBox="0 0 115 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.61818 2.60689H4.17067V12.6678C4.17067 13.6054 4.41583 14.3627 4.90614 14.9397C5.41087 15.5022 6.15354 15.7835 7.13416 15.7835C8.10036 15.7835 8.83582 15.5022 9.34055 14.9397C9.84528 14.3627 10.0976 13.6054 10.0976 12.6678V2.60689H12.6501V12.776C12.6501 13.6126 12.5131 14.3627 12.2391 15.0262C11.9796 15.6753 11.6046 16.2307 11.1143 16.6922C10.624 17.1538 10.04 17.5072 9.36218 17.7524C8.6844 17.9976 7.94173 18.1202 7.13416 18.1202C6.32659 18.1202 5.58392 17.9976 4.90614 17.7524C4.22835 17.5072 3.6371 17.1538 3.13237 16.6922C2.64206 16.2307 2.26712 15.6753 2.00754 15.0262C1.74797 14.3627 1.61818 13.6126 1.61818 12.776V2.60689Z" fill="#1F2C34"/>
      <path d="M15.7272 0.875977H18.0634V17.7524H15.7272V0.875977Z" fill="#1F2C34"/>
      <path d="M22.608 12.776C22.608 13.4107 22.745 13.966 23.019 14.442C23.293 14.9036 23.6607 15.2714 24.1222 15.5455C24.598 15.8196 25.1244 15.9566 25.7012 15.9566C26.1771 15.9566 26.6314 15.8845 27.064 15.7402C27.4966 15.596 27.8788 15.394 28.2105 15.1344C28.5421 14.8748 28.7873 14.5863 28.9459 14.2689V16.8437C28.5998 17.1899 28.1312 17.4639 27.5399 17.6659C26.9486 17.8678 26.2997 17.9688 25.5931 17.9688C24.5692 17.9688 23.6463 17.7524 22.8243 17.3197C22.0167 16.8725 21.375 16.2595 20.8991 15.4806C20.4376 14.6873 20.2069 13.7857 20.2069 12.776C20.2069 11.7519 20.4376 10.8504 20.8991 10.0715C21.375 9.29256 22.0167 8.68674 22.8243 8.25401C23.6463 7.80686 24.5692 7.58328 25.5931 7.58328C26.2997 7.58328 26.9486 7.68425 27.5399 7.88619C28.1312 8.08813 28.5998 8.36219 28.9459 8.70838V11.2831C28.7873 10.9514 28.5349 10.6629 28.1888 10.4177C27.8572 10.158 27.475 9.95608 27.0424 9.81184C26.6098 9.66759 26.1627 9.59547 25.7012 9.59547C25.1244 9.59547 24.598 9.7325 24.1222 10.0066C23.6607 10.2806 23.293 10.6557 23.019 11.1317C22.745 11.6077 22.608 12.1558 22.608 12.776Z" fill="#1F2C34"/>
      <path d="M31.4416 3.84017C31.4416 3.43629 31.5859 3.09732 31.8743 2.82326C32.1771 2.5492 32.5232 2.41216 32.9126 2.41216C33.3308 2.41216 33.6769 2.5492 33.9509 2.82326C34.2393 3.09732 34.3835 3.43629 34.3835 3.84017C34.3835 4.22963 34.2393 4.5686 33.9509 4.85709C33.6769 5.13115 33.3308 5.26818 32.9126 5.26818C32.5232 5.26818 32.1771 5.13115 31.8743 4.85709C31.5859 4.5686 31.4416 4.22963 31.4416 3.84017ZM31.7661 7.79964H34.059V17.7524H31.7661V7.79964Z" fill="#1F2C34"/>
      <path d="M43.6768 11.6942C43.6768 10.9586 43.5254 10.4032 43.2226 10.0282C42.9197 9.65317 42.4511 9.46565 41.8166 9.46565C41.3839 9.46565 41.0018 9.55941 40.6701 9.74693C40.3384 9.92002 40.0788 10.1797 39.8914 10.5258C39.7039 10.8576 39.6102 11.2471 39.6102 11.6942V17.7524H37.274V7.79964H39.6102V9.33583C39.9274 8.74444 40.3312 8.3045 40.8215 8.01601C41.3262 7.72752 41.9247 7.58328 42.6169 7.58328C43.7417 7.58328 44.5998 7.92946 45.191 8.62183C45.7823 9.29977 46.0779 10.2374 46.0779 11.4346V17.7524H43.6768V11.6942Z" fill="#1F2C34"/>
      <path d="M48.7585 3.84017C48.7585 3.43629 48.9027 3.10453 49.1911 2.8449C49.4795 2.57083 49.8256 2.4338 50.2294 2.4338C50.6332 2.4338 50.9721 2.57083 51.2461 2.8449C51.5345 3.10453 51.6787 3.43629 51.6787 3.84017C51.6787 4.22963 51.5345 4.56139 51.2461 4.83545C50.9721 5.10951 50.6332 5.24654 50.2294 5.24654C49.8256 5.24654 49.4795 5.10951 49.1911 4.83545C48.9027 4.56139 48.7585 4.22963 48.7585 3.84017ZM47.0713 19.8944C47.1866 20.0675 47.3308 20.2045 47.5039 20.3055C47.6769 20.4065 47.8788 20.457 48.1096 20.457C48.3403 20.457 48.5278 20.3921 48.672 20.2622C48.8306 20.1469 48.946 19.9593 49.0181 19.6997C49.1046 19.4401 49.1479 19.1083 49.1479 18.7044V7.79964H51.4408V19.1588C51.4408 19.88 51.3182 20.5002 51.0731 21.0195C50.8279 21.5532 50.4602 21.9571 49.9699 22.2312C49.494 22.5197 48.9027 22.6639 48.1961 22.6639C47.8356 22.6639 47.5183 22.6206 47.2443 22.5341C46.9703 22.462 46.7324 22.3466 46.5305 22.1879C46.3142 22.0436 46.1267 21.885 45.9681 21.7119L47.0713 19.8944Z" fill="#1F2C34"/>
      <path d="M56.1302 15.3724L58.4664 16.0431L55.9572 21.4523L54.2267 20.9762L56.1302 15.3724Z" fill="#1F2C34"/>
      <path d="M4.5384 35.5809L3.5001 44.798H0.861084L2.93769 28.8952L9.14587 39.1725L15.3757 28.8952L17.4523 44.798H14.8133L13.7533 35.5809L9.14587 43.3916L4.5384 35.5809Z" fill="#1F2C34"/>
      <path d="M18.9837 39.8216C18.9837 38.7975 19.2144 37.896 19.6759 37.117C20.1518 36.3381 20.7935 35.7323 21.6011 35.2996C22.4087 34.8524 23.3244 34.6289 24.3483 34.6289C25.3722 34.6289 26.2807 34.8524 27.0738 35.2996C27.8814 35.7323 28.5231 36.3381 28.999 37.117C29.4749 37.896 29.7128 38.7975 29.7128 39.8216C29.7128 40.8313 29.4749 41.7328 28.999 42.5262C28.5231 43.3051 27.8814 43.9181 27.0738 44.3653C26.2807 44.798 25.3722 45.0144 24.3483 45.0144C23.3244 45.0144 22.4087 44.798 21.6011 44.3653C20.7935 43.9181 20.1518 43.3051 19.6759 42.5262C19.2144 41.7328 18.9837 40.8313 18.9837 39.8216ZM21.3848 39.8216C21.3848 40.4419 21.5146 40.99 21.7741 41.466C22.0337 41.9276 22.387 42.2954 22.8341 42.5694C23.2811 42.8291 23.7859 42.9589 24.3483 42.9589C24.8963 42.9589 25.3938 42.8291 25.8408 42.5694C26.3023 42.2954 26.6628 41.9276 26.9224 41.466C27.182 40.99 27.3118 40.4419 27.3118 39.8216C27.3118 39.2014 27.182 38.6532 26.9224 38.1772C26.6628 37.7012 26.3023 37.3334 25.8408 37.0738C25.3938 36.8141 24.8963 36.6843 24.3483 36.6843C23.7859 36.6843 23.2811 36.8141 22.8341 37.0738C22.387 37.3334 22.0337 37.7012 21.7741 38.1772C21.5146 38.6532 21.3848 39.2014 21.3848 39.8216Z" fill="#1F2C34"/>
      <path d="M38.3324 38.7398C38.3324 38.0041 38.181 37.4488 37.8781 37.0738C37.5753 36.6987 37.1066 36.5112 36.4721 36.5112C36.0395 36.5112 35.6573 36.605 35.3256 36.7925C34.994 36.9656 34.7344 37.2252 34.5469 37.5714C34.3594 37.9032 34.2657 38.2926 34.2657 38.7398V44.798H31.9295V34.8452H34.2657V36.3814C34.583 35.79 34.9868 35.3501 35.4771 35.0616C35.9818 34.7731 36.5803 34.6289 37.2725 34.6289C38.3973 34.6289 39.2553 34.975 39.8466 35.6674C40.4378 36.3453 40.7335 37.2829 40.7335 38.4801V44.798H38.3324V38.7398Z" fill="#1F2C34"/>
      <path d="M42.3325 34.8452H47.9783V36.9007H42.3325V34.8452ZM43.9981 31.3834H46.3126V44.798H43.9981V31.3834Z" fill="#1F2C34"/>
      <path d="M53.9157 45.0144C52.9062 45.0144 52.0122 44.798 51.2334 44.3653C50.4691 43.9325 49.8779 43.3267 49.4597 42.5478C49.0415 41.7689 48.8324 40.8602 48.8324 39.8216C48.8324 38.7686 49.0415 37.8527 49.4597 37.0738C49.8923 36.2949 50.498 35.6963 51.2767 35.278C52.0554 34.8452 52.9711 34.6289 54.0239 34.6289C55.0766 34.6289 55.9707 34.8308 56.7061 35.2347C57.4416 35.6386 58.004 36.2227 58.3934 36.9872C58.7972 37.7373 58.9991 38.646 58.9991 39.7134C58.9991 39.8288 58.9918 39.9514 58.9774 40.0812C58.9774 40.2111 58.9702 40.3048 58.9558 40.3625H50.2168V38.7614H56.9657L56.2519 39.7567C56.2951 39.6702 56.3384 39.5548 56.3817 39.4105C56.4394 39.2518 56.4682 39.122 56.4682 39.0211C56.4682 38.4874 56.36 38.0258 56.1437 37.6363C55.9418 37.2469 55.6534 36.944 55.2785 36.7276C54.918 36.5112 54.4925 36.403 54.0022 36.403C53.411 36.403 52.9062 36.5329 52.488 36.7925C52.0698 37.0521 51.7526 37.4272 51.5363 37.9176C51.32 38.408 51.2046 39.0138 51.1902 39.7351C51.1902 40.4563 51.2983 41.0693 51.5146 41.5742C51.7309 42.0646 52.0482 42.4396 52.4664 42.6993C52.899 42.9589 53.4182 43.0887 54.0239 43.0887C54.6584 43.0887 55.2136 42.9589 55.6895 42.6993C56.1654 42.4396 56.5619 42.0429 56.8792 41.5093L58.8909 42.3314C58.3717 43.2257 57.7012 43.8965 56.8792 44.3436C56.0572 44.7908 55.0694 45.0144 53.9157 45.0144Z" fill="#1F2C34"/>
      <path d="M67.6318 38.7398C67.6318 38.0041 67.4804 37.4488 67.1775 37.0738C66.8747 36.6987 66.406 36.5112 65.7715 36.5112C65.3389 36.5112 64.9567 36.605 64.6251 36.7925C64.2934 36.9656 64.0338 37.2252 63.8463 37.5714C63.6589 37.9032 63.5651 38.2926 63.5651 38.7398V44.798H61.2289V34.8452H63.5651V36.3814C63.8824 35.79 64.2862 35.3501 64.7765 35.0616C65.2812 34.7731 65.8797 34.6289 66.5719 34.6289C67.6967 34.6289 68.5547 34.975 69.146 35.6674C69.7373 36.3453 70.0329 37.2829 70.0329 38.4801V44.798H67.6318V38.7398Z" fill="#1F2C34"/>
      <path d="M77.3426 45.0144C76.3331 45.0144 75.439 44.798 74.6603 44.3653C73.896 43.9325 73.3047 43.3267 72.8865 42.5478C72.4683 41.7689 72.2592 40.8602 72.2592 39.8216C72.2592 38.7686 72.4683 37.8527 72.8865 37.0738C73.3191 36.2949 73.9248 35.6963 74.7035 35.278C75.4823 34.8452 76.398 34.6289 77.4507 34.6289C78.5034 34.6289 79.3975 34.8308 80.133 35.2347C80.8685 35.6386 81.4309 36.2227 81.8202 36.9872C82.224 37.7373 82.4259 38.646 82.4259 39.7134C82.4259 39.8288 82.4187 39.9514 82.4043 40.0812C82.4043 40.2111 82.3971 40.3048 82.3827 40.3625H73.6436V38.7614H80.3926L79.6787 39.7567C79.722 39.6702 79.7653 39.5548 79.8085 39.4105C79.8662 39.2518 79.8951 39.122 79.8951 39.0211C79.8951 38.4874 79.7869 38.0258 79.5706 37.6363C79.3687 37.2469 79.0803 36.944 78.7053 36.7276C78.3448 36.5112 77.9194 36.403 77.4291 36.403C76.8378 36.403 76.3331 36.5329 75.9149 36.7925C75.4967 37.0521 75.1794 37.4272 74.9631 37.9176C74.7468 38.408 74.6314 39.0138 74.617 39.7351C74.617 40.4563 74.7252 41.0693 74.9415 41.5742C75.1578 42.0646 75.4751 42.4396 75.8933 42.6993C76.3259 42.9589 76.845 43.0887 77.4507 43.0887C78.0852 43.0887 78.6404 42.9589 79.1163 42.6993C79.5922 42.4396 79.9888 42.0429 80.306 41.5093L82.3178 42.3314C81.7986 43.2257 81.128 43.8965 80.306 44.3436C79.4841 44.7908 78.4962 45.0144 77.3426 45.0144Z" fill="#1F2C34"/>
      <path d="M84.0934 45.4687H86.4079C86.4223 45.9159 86.5449 46.3198 86.7757 46.6804C87.0208 47.041 87.3525 47.3223 87.7707 47.5242C88.1889 47.7261 88.672 47.8271 89.22 47.8271C89.7103 47.8271 90.179 47.7117 90.626 47.4809C91.0731 47.2501 91.4336 46.8967 91.7076 46.4207C91.996 45.9447 92.1402 45.3317 92.1402 44.5816V34.8452H94.4331V44.5816C94.4331 45.7788 94.188 46.7669 93.6977 47.5458C93.2218 48.3247 92.5945 48.9017 91.8158 49.2767C91.037 49.6662 90.1934 49.8609 89.2849 49.8609C88.2033 49.8609 87.2804 49.6734 86.5161 49.2984C85.7518 48.9378 85.1605 48.4257 84.7423 47.7622C84.3385 47.0987 84.1222 46.3342 84.0934 45.4687ZM83.8987 39.7783C83.8987 38.6677 84.1222 37.7373 84.5693 36.9872C85.0163 36.2227 85.6076 35.6386 86.343 35.2347C87.0929 34.8308 87.8933 34.6289 88.7441 34.6289C89.5805 34.6289 90.3088 34.838 90.9289 35.2563C91.5634 35.6602 92.0609 36.2444 92.4214 37.0089C92.7964 37.7734 92.9838 38.6965 92.9838 39.7783C92.9838 40.8457 92.7964 41.7617 92.4214 42.5262C92.0609 43.2907 91.5634 43.882 90.9289 44.3004C90.3088 44.7042 89.5805 44.9062 88.7441 44.9062C87.8933 44.9062 87.0929 44.7042 86.343 44.3004C85.6076 43.8965 85.0163 43.3123 84.5693 42.5478C84.1222 41.7833 83.8987 40.8602 83.8987 39.7783ZM86.3214 39.7783C86.3214 40.4419 86.4584 41.0044 86.7324 41.466C87.0064 41.9276 87.3669 42.281 87.814 42.5262C88.2754 42.757 88.7729 42.8723 89.3065 42.8723C89.7536 42.8723 90.1934 42.757 90.626 42.5262C91.0731 42.2954 91.4336 41.9492 91.7076 41.4876C91.996 41.0116 92.1402 40.4419 92.1402 39.7783C92.1402 39.2591 92.0537 38.8119 91.8807 38.4369C91.722 38.0474 91.5057 37.7229 91.2317 37.4632C90.9577 37.1892 90.6477 36.9872 90.3016 36.8574C89.9699 36.7276 89.6382 36.6627 89.3065 36.6627C88.7729 36.6627 88.2754 36.7853 87.814 37.0305C87.3669 37.2613 87.0064 37.6075 86.7324 38.0691C86.4584 38.5306 86.3214 39.1004 86.3214 39.7783Z" fill="#1F2C34"/>
      <path d="M99.6449 34.8452V44.798H97.3304V34.8452H99.6449ZM102.608 37.3334C102.392 37.1603 102.19 37.0305 102.003 36.944C101.815 36.8574 101.577 36.8141 101.289 36.8141C100.9 36.8141 100.582 36.9151 100.337 37.117C100.092 37.319 99.9117 37.6003 99.7963 37.9609C99.6954 38.3071 99.6449 38.7181 99.6449 39.1941L98.8662 38.7398C98.8662 37.9464 99.0104 37.2469 99.2988 36.641C99.6017 36.0208 99.9838 35.5304 100.445 35.1698C100.921 34.8092 101.411 34.6289 101.916 34.6289C102.277 34.6289 102.616 34.6866 102.933 34.8019C103.25 34.9029 103.517 35.0904 103.733 35.3645L102.608 37.3334Z" fill="#1F2C34"/>
      <path d="M104.263 39.8216C104.263 38.7975 104.493 37.896 104.955 37.117C105.431 36.3381 106.072 35.7323 106.88 35.2996C107.687 34.8524 108.603 34.6289 109.627 34.6289C110.651 34.6289 111.559 34.8524 112.353 35.2996C113.16 35.7323 113.802 36.3381 114.278 37.117C114.754 37.896 114.992 38.7975 114.992 39.8216C114.992 40.8313 114.754 41.7328 114.278 42.5262C113.802 43.3051 113.16 43.9181 112.353 44.3653C111.559 44.798 110.651 45.0144 109.627 45.0144C108.603 45.0144 107.687 44.798 106.88 44.3653C106.072 43.9181 105.431 43.3051 104.955 42.5262C104.493 41.7328 104.263 40.8313 104.263 39.8216ZM106.664 39.8216C106.664 40.4419 106.793 40.99 107.053 41.466C107.313 41.9276 107.666 42.2954 108.113 42.5694C108.56 42.8291 109.065 42.9589 109.627 42.9589C110.175 42.9589 110.673 42.8291 111.12 42.5694C111.581 42.2954 111.942 41.9276 112.201 41.466C112.461 40.99 112.591 40.4419 112.591 39.8216C112.591 39.2014 112.461 38.6532 112.201 38.1772C111.942 37.7012 111.581 37.3334 111.12 37.0738C110.673 36.8141 110.175 36.6843 109.627 36.6843C109.065 36.6843 108.56 36.8141 108.113 37.0738C107.666 37.3334 107.313 37.7012 107.053 38.1772C106.793 38.6532 106.664 39.2014 106.664 39.8216Z" fill="#1F2C34"/>
    </svg>
    </div>
  </div>
  )
}

export default KosovoMap